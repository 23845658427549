<template>
  <OnboardingStep v-bind="$props">
    <router-view v-bind="$props"></router-view>
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";

export default {
  name: "RouterSetupView",
  extends: BaseStepView,
};
</script>

<style lang="scss" scoped></style>
